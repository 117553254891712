/* eslint-disable */
// WARNING: DO NOT EDIT.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USERPOOLID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOLWEBCLIENTID,
  oauth: {},
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
  },
};

export default awsmobile;
