import { AuthWrapper, Footer, SignupFormEmail } from 'components/organisms';

export const SignupPageEmail = () => {
  return (
    <>
      <AuthWrapper>
        <SignupFormEmail />
      </AuthWrapper>
      <Footer />
    </>
  );
};
