import { AuthWrapper, Footer, SignupFormPassword } from 'components/organisms';

export const SignupPagePassword = () => {
  return (
    <>
      <AuthWrapper>
        <SignupFormPassword />
      </AuthWrapper>
      <Footer />
    </>
  );
};
